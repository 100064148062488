define('ember-ghost-casper-template/helpers/url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.url = url;
  function url(params /*, hash*/) {
    return params;
  }

  exports.default = Ember.Helper.helper(url);
});